var sendGaNotLoadedWarning = true;
window.sendEvent = function(label, metadata) {
    if ("mixpanel" in window) {
        if (typeof mixpanel.track === "function") {
            mixpanel.track(label, metadata);
        }
    } else {
        if (sendGaNotLoadedWarning) {
            console.warn("Cannot send event");
            sendGaNotLoadedWarning = false;
        }
    }
}

window.sendEvent("StreamSaverSupport", { "support": window.StreamSaver.supported });

try {
    const blobSupported = !!new Blob;
    window.sendEvent("BlobSupport", { "support": blobSupported });
} catch (error) {
    window.sendEvent("BlobSupport", { "support": false });
}
